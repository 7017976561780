const projectData = [
    {
      id: 1,
      title: "Audio Bid",
      description: "AudioBid is a Django-based voice transcription job portal with dynamic pricing and Google OAuth integration. It offers multiple audio upload options and utilizes AWS S3 for storage and streaming. The platform enables users to claim and get paid for jobs, along with a chat system for seamless communication.",
      image: require("../assets/AudioBidProject.png"),
      demoLink: "https://audiobid.herokuapp.com/",
      githubLink: "https://github.com/vasunverma/binary-classification-high-dim-data",
      tags: ["Python", "Django", "JavaScript", "S3", "PostgreSQL", "SES", "OAuth", "HTML/CSS", "Heroku"],
      tagColor: ["blue", "red", "yellow", "orange", "teal", "green", "purple", "pink", "red"],
      rowSpan: 1,
      columnSpan: 2,
    },
    {
      id: 2,
      title: "Music MatchMaker",
      description: "Music MatchMaker is a recommendation system for music utilizing a custom Natural Language Processing model. Preprocessed and analyzed song lyrics using TF-IDF vectorization and cosine similarity to generate song recommendations based on user preferences. Integrated this model into a Flask application, enhancing user experience by providing direct Spotify links to the recommended songs.",
      image: require("../assets/MusicMatchmaker.png"),
      githubLink: "https://github.com/vasunverma/MusicMatchmaker",
      tags: ["sklearn", "pandas", "TfidfVectorizer", "PorterStemmer", "Python", "Flask", "Spotify API", "JavaScript", "HTML/CSS"],
      tagColor: ["blue", "red", "yellow", "orange", "teal", "green", "purple", "pink", "red", "yellow"],
      rowSpan: 1,
      columnSpan: 2,
    },
    {
      id: 3,
      title: "Binary Classification with High-Dimensional Data",
      description: "Binary classification models were developed using R, Caret, Baruta, XgBoost, Tensorflow, Keras, and RandomForest for binary classification with high-dimensional data. Predictor reduction techniques were applied, achieving 20 predictors while maintaining high predictive power. Rigorous cross-validation techniques led to 82% accuracy on the validation set, accompanied by insightful visualizations and statistical summaries.",
      //image: require("../assets/BinaryClassificationProject.png"),
      githubLink: "https://github.com/vasunverma/binary-classification-high-dim-data",
      tags: ["R", "XgBoost", "Baruta", "Tensorflow", "PCA", "Keras", "RandomForest", "Caret"],
      tagColor: ["blue", "red", "yellow", "orange", "teal", "green", "purple", "pink"],
      rowSpan: 1,
      columnSpan: 1,
    },
    {
      id: 4,
      title: "Distributed Graph Matching on Undirected Graphs",
      description: "In an undirected graph, a matching is a set of edges with no shared vertices between any two edges. A maximum matching is one that contains the largest possible number of edges. Finding a maximal matching, which is a 2-approximation to the maximum matching, is often simpler. A matching is maximal if adding any other edge would violate the matching condition. \nFor large graphs, a distributed algorithm can be used to find a maximal matching by evenly distributing the edges across multiple machines. In my project, I conducted experiments to analyze how the latency of the distributed matching algorithm changes with the number of machines involved.",
      //image: require("../assets/BinaryClassificationProject.png"),
      githubLink: "https://github.com/vasunverma/distributed-graph-matching",
      tags: ["Python", "Numpy", "Networkx", "Matplotlib", "Random", "Math","Time"],
      tagColor: ["teal", "green", "purple", "pink", "blue", "red", "yellow"],
      rowSpan: 1,
      columnSpan: 1,
    },
    {
      id: 5,
      title: "Smart Air Purifier",
      description: "A budget-friendly smart air purifier was designed and developed using C++, Arduino, AWS IoT, HTML/CSS, JavaScript, and P5.js. The purifier integrated dust, temperature, and humidity sensors to provide real-time air quality feedback. It featured an automatic switch-on function triggered by high dust density and utilized MQTT protocol for real-time communication with the web app. The purifier effectively cleaned indoor air using HEPA H13 and activated carbon filters.",
      image: require("../assets/SmartAirPurifierProject.jpg"),
      tags: ["C++", "Arduino", "AWS IoT", "JavaScript", "HTML/CSS", "P5.js", "MQTT"],
      tagColor: ["blue", "red", "yellow", "orange", "teal", "green", "purple"],
      rowSpan: 1,
      columnSpan: 2,
    },
    {
        id: 6,
        title: "Timer App",
        description: "Timer App is a sleek and user-friendly desktop application developed with Electron.js, providing a seamless and customizable timer experience for various purposes.",
        // image: require("../assets/SmartAirPurifierProject.jpg"),
        githubLink: "https://github.com/vasunverma/Timer-App",
        tags: ["Electron", "Javascript", "HTML", "CSS"],
        tagColor: ["orange", "teal", "green", "purple"],
        rowSpan: 1,
        columnSpan: 1,
    },
    {
        id: 7,
        title: "URL Shortener",
        description: "URL Shortener is a web application developed using Flask, HTML, and CSS. It enables users to shorten long URLs and provides a convenient way to share them.",
        // image: require("../assets/SmartAirPurifierProject.jpg"),
        githubLink: "https://github.com/vasunverma/URL-Shortner",
        tags: ["Flask", "Python", "Javascript", "HTML", "CSS"],
        tagColor: ["blue", "red", "yellow", "orange", "teal"],
        rowSpan: 1,
        columnSpan: 1,
    }
  ];
  
  export default projectData;
  